const PaywallView = () => import(/* webpackChunkName: "plan" */ '@/modules/Paywall/PaywallView.vue')

const paywallInstances = {
	email: {
		name: 'plan-email',
		noAuth: true,
	},
	'email-f': {
		name: 'plan-email-female',
		noAuth: true,
	},
	'email-f-commitment': {
		name: 'plan-email-female-commitment',
		noAuth: true,
	},
	'email-activity': {
		name: 'plan-email-activity',
		noAuth: true,
	},
	'email-activity-m': {
		name: 'plan-email-activity-male',
		noAuth: true,
	},
	'email-activity-commitment': {
		name: 'plan-email-activity-commitment',
		noAuth: true,
	},
	'email-pilates': {
		name: 'plan-email-pilates',
		noAuth: true,
	},
	'email-pilates-commitment': {
		name: 'plan-email-pilates-commitment',
		noAuth: true,
	},
	'email-yoga': {
		name: 'plan-email-yoga',
		noAuth: true,
	},
	'email-yoga-m': {
		name: 'plan-email-yoga-male',
		noAuth: true,
	},
	'email-yoga-commitment': {
		name: 'plan-email-yoga-commitment',
		noAuth: true,
	},
	'email-somatic-yoga': {
		name: 'plan-email-somatic-yoga',
		noAuth: true,
	},
	'email-walking-f': {
		name: 'plan-email-walking',
		noAuth: true,
	},
	'email-walking-m': {
		name: 'plan-email-walking-male',
		noAuth: true,
	},
	main: {
		name: 'plan-main',
	},
	m: {
		name: 'plan-male',
	},
	'activity-m': {
		name: 'plan-male-activity',
	},
	'm-commitment': {
		name: 'plan-male-commitment',
	},
	f: {
		name: 'plan-female',
	},
	'f-commitment': {
		name: 'plan-female-commitment',
	},
	'activity-f': {
		name: 'plan-female-activity',
	},
	'activity-f-commitment': {
		name: 'plan-female-activity-commitment',
	},
	'pilates-f': {
		name: 'plan-female-activity-pilates',
	},
	'pilates-f-commitment': {
		name: 'plan-female-activity-pilates-commitment',
	},
	'yoga-f': {
		name: 'plan-female-activity-chair-yoga',
	},
	'yoga-f-commitment': {
		name: 'plan-female-activity-chair-yoga-commitment',
	},
	'yoga-m': {
		name: 'plan-male-activity-chair-yoga',
	},
	'somatic-yoga-f': {
		name: 'plan-female-activity-somatic-yoga',
	},
	'walking-f': {
		name: 'plan-female-activity-walking',
	},
	'walking-f-us': {
		name: 'plan-female-activity-walking-us',
	},
	'walking-m': {
		name: 'plan-male-activity-walking',
	},
}

const paywallRoutesList = Object.keys(paywallInstances).map((id) => ({
	path: `/plan/${id}`,
	name: paywallInstances[id].name,
	component: PaywallView,
	props: { id },
	meta: {
		requiresOnboarding: !Boolean(paywallInstances[id].noAuth),
		requiresUser: !Boolean(paywallInstances[id].noAuth),
		pageAnalyticUxEvent: {
			name: 'ScreenView',
			data: {
				title: 'OnboardingCreatedProgramScreen',
			},
		},
	},
}))

export default paywallRoutesList
