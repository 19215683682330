import LandingSwap from '@/components/LandingSwap.vue'

export default [
	// GENDER SELECTION
	{
		path: '/survey/:page*',
		name: 'survey',
		component: () => import(/* webpackChunkName: "ob-survey" */ '@/modules/Onboarding/OnboardingView.vue'),
	},

	// FEMALE
	{
		path: '/f',
		name: 'female-funnel',
		component: LandingSwap,
		meta: {
			isLanding: true,
		},
	},
	{
		path: '/f/survey/:page*',
		name: 'female-survey',
		component: () => import(/* webpackChunkName: "ob-survey" */ '@/modules/Onboarding/OnboardingView.vue'),
	},

	// MALE
	{
		path: '/m',
		name: 'male-funnel',
		component: LandingSwap,
		meta: {
			isLanding: true,
		},
	},
	{
		path: '/m/survey/:page*',
		name: 'male-survey',
		component: () => import(/* webpackChunkName: "ob-survey" */ '@/modules/Onboarding/OnboardingView.vue'),
	},
]
