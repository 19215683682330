import config from '@/config'

export default {
	id: 'SIMPLE_UPGRADE_9M_99',
	providers: {
		stripe: config('stripe_cheap_and_short_upgrade_9_month'),
		braintree: 'pro_short_cheap_upgrade',
		paltaMap: {
			USD: 'simple_premium_9m_99_usd',
			EUR: 'simple_premium_9m_99_eur',
			GBP: 'simple_premium_9m_99_gbp',
			AUD: 'simple_premium_9m_149_aud',
			CAD: 'simple_premium_9m_149_cad',
			DKK: 'simple_premium_9m_739_dkk',
			SEK: 'simple_premium_9m_1179_sek',
			NOK: 'simple_premium_9m_1179_nok',
			BRL: 'simple_premium_9m_489_brl',
			MXN: 'simple_premium_9m_1659_mxn',
			TRY: 'simple_premium_9m_3399_try',
		},
	},
	trial: null,
	subscription: {
		interval: 'month',
		intervalCount: 9,
		priceMap: {
			USD: 99,
			EUR: 99,
			GBP: 99,
			AUD: 149,
			CAD: 149,
			DKK: 739,
			SEK: 1179,
			NOK: 1179,
			BRL: 489,
			MXN: 1659,
			TRY: 3399,
		},
	},
	options: () => ({}),
}
