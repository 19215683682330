<template>
	<component :is="componentName" />
</template>

<script>
import { VUE_APP_VERSION } from '@/helpers/environment'
import { mapGetters, mapMutations } from 'vuex'

import { os, version, browserEngine, browserName, browserVersion } from '@/helpers/device'
import OpenTelemetry from '@/modules/OpenTelemetry'
import Jet from '@/modules/Jet'

export default {
	name: 'LandingSwap',
	components: {
		LandingMale: () => import(/* webpackChunkName: "landing-male" */ '@/views/landings/LandingMale.vue'),
		LandingFemale: () => import(/* webpackChunkName: "landing-female" */ '@/views/landings/LandingFemale.vue'),
		LandingActivity: () =>
			import(/* webpackChunkName: "landing-activity-female" */ '@/views/landings/LandingActivity.vue'),
		LandingAgeSelection: () =>
			import(/* webpackChunkName: "landing-age-selection" */ '@/views/landings/LandingAgeSelection.vue'),
		LandingOnboardingStyle: () =>
			import(/* webpackChunkName: "landing-onboarding-style" */ '@/views/landings/LandingOnboardingStyle.vue'),
		LandingGenderSelection: () =>
			import(/* webpackChunkName: "landing-gender-selection" */ '@/views/landings/LandingGenderSelection.vue'),
		LandingForbes: () => import(/* webpackChunkName: "landing-forbes" */ '@/views/landings/LandingForbes.vue'),
		HealthCoach: () => import(/* webpackChunkName: "your-health-coach" */ '@/views/personal-plan/HealthCoach.vue'),
	},
	computed: {
		...mapGetters({
			language: 'i18n/getLanguage',
			domain: 'location/domain',
			hostname: 'location/hostname',
			getQueryParam: 'location/getQueryParam',
			isForbesAffiliateTraffic: 'location/isForbesAffiliateTraffic',
			onetrustActiveGroups: 'onetrust/getActiveGroups',
			userStep: 'survey/getUserStepCount',
			onboardingStep: 'survey/getOnboardingStepCount',
		}),
		componentName() {
			if (this.component) {
				return this.component
			}

			if (this.domain.HEALTH_COACH) {
				return 'HealthCoach'
			}

			if (this.isForbesAffiliateTraffic) {
				return 'LandingForbes'
			}

			if (this.IS_ACTIVITY_WELCOME_FOR_GOOGLE_EXP) {
				return 'LandingFemale'
			}

			if (
				this.IS_GOOGLE_BMI_CAMPAIGN_EXP ||
				this.IS_GOOGLE_CALORIE_DEFICIT_CAMPAIGN_EXP ||
				this.IS_GOOGLE_FASTING_CAMPAIGN_EXP ||
				this.IS_MALE_ACTIVITY_WALKING_OB_FUNNEL ||
				this.IS_MALE_ACTIVITY_CHAIR_YOGA_OB_FUNNEL
			) {
				return 'LandingAgeSelection'
			}

			if (this.IS_GOOGLE_GOAL_CAMPAIGN_EXP || this.IS_GOOGLE_MEAL_PLAN_CAMPAIGN_EXP) {
				return 'LandingOnboardingStyle'
			}

			if (
				this.IS_MALE_ACTIVITY_OB_FUNNEL ||
				this.IS_FEMALE_ACTIVITY_OB_FUNNEL ||
				this.IS_FEMALE_ACTIVITY_PILATES_OB_FUNNEL ||
				this.IS_FEMALE_ACTIVITY_CHAIR_YOGA_OB_FUNNEL ||
				this.IS_FEMALE_ACTIVITY_BEFORE_PREDICT_EXP ||
				this.IS_FEMALE_ACTIVITY_SOMATIC_YOGA_OB_FUNNEL ||
				this.IS_FEMALE_ACTIVITY_WALKING_OB_FUNNEL
			) {
				return 'LandingActivity'
			} else if (['female', 'aff-female', 'wildcard-female'].includes(this.appArea)) {
				if (this.IS_TOP_OF_THE_FUNNEL_OPTIMIZATION_EXP) {
					return 'LandingActivity'
				}

				return 'LandingFemale'
			} else if (['male', 'aff-male', 'wildcard-male'].includes(this.appArea)) {
				return 'LandingMale'
			} else if (['main-activity', 'main-walking', 'main-yoga'].includes(this.appArea)) {
				return 'LandingAgeSelection'
			} else {
				return 'LandingGenderSelection'
			}
		},
	},

	created() {
		this.resetAnalyticDataCounter()
		const utmCampaign = this.getQueryParam('utm_campaign')
			? decodeURIComponent(this.getQueryParam('utm_campaign'))
			: null
		const utmContent = this.getQueryParam('utm_content') ? decodeURIComponent(this.getQueryParam('utm_content')) : null
		const utmTerm = this.getQueryParam('utm_term') ? decodeURIComponent(this.getQueryParam('utm_term')) : null

		this.$analytic
			.setUserProperties({
				Device: { os, version, height: window.innerHeight, width: window.innerWidth },
				browserEngine,
				browserName,
				browserVersion,
				SurveyVersion: VUE_APP_VERSION,
				locale: this.$i18n.locale,
				CookieConsentActiveGroups: this.onetrustActiveGroups,
				hostname: this.hostname,
				from: this.getQueryParam('from'),
				AdgroupId: this.getQueryParam('adgroup_id'),
				AdId: this.getQueryParam('ad_id'),
				CampaignId: this.getQueryParam('campaign_id'),
				UtmGender: this.getQueryParam('utm_gender'),
				UtmMedium: this.getQueryParam('utm_medium'),
				UtmPartnerId: this.getQueryParam('utm_partner_id'),
				UtmSource: this.getQueryParam('utm_source'),
				UtmCampaign: utmCampaign,
				UtmContent: utmContent,
				UtmTerm: utmTerm,
			})
			.then(() => {
				this.$analytic.logEvent('ScreenView', { title: 'OnboardingWelcomeScreen' })
				this.$analytic.logEvent('OnboardingStart', { from: this.getQueryParam('from') })
			})

		Jet.logCoreEvent('OnboardingStart')
	},

	beforeRouteEnter(to, from, next) {
		if (from.path === '/') {
			OpenTelemetry.startSpan('vue-landing-mounted', {
				startTime: window.ot.startTime,
				attributes: { 'span.type': 'vue-landing-mounted' },
			})
		}
		next()
	},

	methods: mapMutations({
		resetAnalyticDataCounter: 'survey/resetAnalyticDataCounter',
		incrementUserStep: 'survey/incrementUserStep',
		setOnboardingStep: 'survey/setOnboardingStepCount',
	}),
}
</script>
