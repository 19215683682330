import config from '@/config'

export default {
	id: 'SIMPLE_UPGRADE_9M_79_2',
	providers: {
		stripe: config('stripe_cheap_and_short_upgrade_9_month'),
		braintree: 'pro_short_cheap_upgrade',
		paltaMap: {
			USD: 'simple_premium_9m_79_2_usd',
			EUR: 'simple_premium_9m_79_2_eur',
			GBP: 'simple_premium_9m_79_2_gbp',
			AUD: 'simple_premium_9m_119_aud',
			CAD: 'simple_premium_9m_119_2_cad',
			DKK: 'simple_premium_9m_591_2_dkk',
			SEK: 'simple_premium_9m_943_2_sek',
			NOK: 'simple_premium_9m_943_2_nok',
			BRL: 'simple_premium_9m_391_2_brl',
			MXN: 'simple_premium_9m_1327_2_mxn',
			TRY: 'simple_premium_9m_2699_try',
		},
	},
	trial: null,
	subscription: {
		interval: 'month',
		intervalCount: 9,
		priceMap: {
			USD: 79.2,
			EUR: 79.2,
			GBP: 79.2,
			AUD: 119.2,
			CAD: 119.2,
			DKK: 591.2,
			SEK: 943.2,
			NOK: 943.2,
			BRL: 391.2,
			MXN: 1327.2,
			TRY: 2699,
		},
	},
	options: () => ({}),
}
