export default {
	id: 'SIMPLE_UPGRADE_6M_107_95',
	providers: {
		stripe: '',
		braintree: '',
		paltaMap: {
			USD: 'simple_premium_6m_107_95_usd',
			EUR: 'simple_premium_6m_107_95_eur',
			GBP: 'simple_premium_6m_107_95_gbp',
			AUD: 'simple_premium_6m_149_aud',
			CAD: 'simple_premium_6m_149_cad',
			SEK: 'simple_premium_6m_1199_sek',
			BRL: 'simple_premium_6m_529_brl',
			DKK: 'simple_premium_6m_779_dkk',
			NOK: 'simple_premium_6m_1199_nok',
			MXN: 'simple_premium_6m_1799_mxn',
			TRY: 'simple_premium_6m_3699_try',
		},
	},
	trial: null,
	subscription: {
		interval: 'month',
		intervalCount: 6,
		priceMap: {
			USD: 107.95,
			EUR: 107.95,
			GBP: 107.95,
			AUD: 149,
			CAD: 149,
			SEK: 1199,
			BRL: 529,
			DKK: 779,
			NOK: 1199,
			MXN: 1799,
			TRY: 3699,
		},
	},
	options: ($t) => ({
		currentSubscriptionRateText: ({ trialPrice, price }) => {
			return $t('activity_upgrade.6mo.subtitle_1', { trialPrice, price })
		},
		upgradeOfferText: ({ price }) => {
			return $t('activity_upgrade.6mo.subtitle_2', { price })
		},
		barChartTooltip: $t('activity_upgrade.6mo.bar_chart_tooltip'),
		barChartLeftLabel: $t('activity_upgrade.6mo.bar_chart_left_label'),
		barChartRightLabel: $t('activity_upgrade.6mo.bar_chart_right_label'),
		upgradeRate: $t('activity_upgrade.6mo.upgrade_rate'),
		currentPriceLabel: $t('activity_upgrade.6mo.current_price_label'),
		offerPriceLabel: $t('activity_upgrade.6mo.offer_price_label'),
	}),
}
