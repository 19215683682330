export default {
	id: 'SIMPLE_UPGRADE_3M_44_97',
	providers: {
		stripe: '',
		braintree: '',
		paltaMap: {
			USD: 'simple_premium_3m_44_97_usd',
			EUR: 'simple_premium_3m_44_97_eur',
			GBP: 'simple_premium_3m_44_97_gbp',
			AUD: 'simple_premium_3m_65_aud',
			CAD: 'simple_premium_3m_65_cad',
			SEK: 'simple_premium_3m_529_sek',
			BRL: 'simple_premium_3m_229_brl',
			DKK: 'simple_premium_3m_329_dkk',
			NOK: 'simple_premium_3m_529_nok',
			MXN: 'simple_premium_3m_759_mxn',
			TRY: 'simple_premium_3m_1499_try',
		},
	},
	trial: null,
	subscription: {
		interval: 'month',
		intervalCount: 3,
		priceMap: {
			USD: 44.97,
			EUR: 44.97,
			GBP: 44.97,
			AUD: 65,
			CAD: 65,
			SEK: 529,
			BRL: 229,
			DKK: 329,
			NOK: 529,
			MXN: 759,
			TRY: 1499,
		},
	},
	options: ($t) => ({
		currentSubscriptionRateText: ({ trialPrice, price }) => {
			return $t('activity_upgrade.3mo.subtitle_1', { trialPrice, price })
		},
		upgradeOfferText: ({ price }) => {
			return $t('activity_upgrade.3mo.subtitle_2', { price })
		},
		barChartTooltip: $t('activity_upgrade.3mo.bar_chart_tooltip'),
		barChartLeftLabel: $t('activity_upgrade.3mo.bar_chart_left_label'),
		barChartRightLabel: $t('activity_upgrade.3mo.bar_chart_right_label'),
		upgradeRate: $t('activity_upgrade.3mo.upgrade_rate'),
		currentPriceLabel: $t('activity_upgrade.3mo.current_price_label'),
		offerPriceLabel: $t('activity_upgrade.3mo.offer_price_label'),
	}),
}
