const FunnelsConfig = require('./funnels_config.json')

const EXPERIMENTAL_FLOW_FUNNELS = {
	FEMALE_FUNNEL_EVENT_PERSONALIZATION: {
		id: 'ob-female-event-personalization',
		theme: 'light',
		locale: ['en'],
		slug: 'female',
	},
	FEMALE_FUNNEL_AUTOTEST_CHECK: {
		id: 'ob-female-autotest-check',
		theme: 'light',
		locale: ['en'],
		slug: 'female',
	},
	FEMALE_ACTIVITY_WALKING_FUNNEL_NEW_START: {
		id: 'ob-female-activity-walking-new-start',
		theme: 'light',
		locale: ['en'],
		slug: 'female-activity-walking',
	},
	FEMALE_ACTIVITY_FUNNEL_ONBOARDING_BUILDER_CRO_V1: {
		id: 'ob-female-activity-cro-v1',
		theme: 'light',
		locale: ['en'],
		slug: 'female-activity-v2',
	},
	FEMALE_ACTIVITY_NEW_UI_ONBOARDING_BUILDER: {
		id: 'ob-female-activity-new-ui',
		theme: 'light',
		locale: ['en'],
		slug: 'female-activity-v2',
	},
	FEMALE_ACTIVITY_OPTIMIZATION_FOR_CTA: {
		id: 'ob-female-activity-cta-opt',
		theme: 'light',
		locale: ['en'],
		slug: 'female-activity-v2',
	},
	FEMALE_ACTIVITY_CHAIR_YOGA_OPTIMIZATION_FOR_CTA: {
		id: 'ob-female-activity-chair-yoga-cta-opt',
		theme: 'light',
		locale: ['en'],
		slug: 'female-activity-yoga',
	},
	FEMALE_ACTIVITY_CHAIR_YOGA_EVENT_PERSONALIZATION: {
		id: 'ob-female-activity-chair-yoga-event-personalisation',
		theme: 'light',
		locale: ['en'],
		slug: 'female-activity-yoga',
	},
}

const ACTIVITY_FUNNELS = {
	MAIN_ACTIVITY_FUNNEL_ONBOARDING_BUILDER: {
		id: 'ob-activity-gender-selection',
		theme: 'light',
		locale: ['en'],
		slug: 'main-activity',
		redirectTo: [
			{
				appArea: 'activity-male',
				routeName: 'male-activity-onboarding',
				condition: (ctx) => ctx.sex?.includes('male'),
			},
			{
				appArea: 'activity-female-v2',
				routeName: 'female-activity-onboarding',
				condition: (ctx) => ctx.sex?.includes('female'),
			},
		],
	},
	MALE_ACTIVITY_FUNNEL_ONBOARDING_BUILDER: {
		id: 'ob-male-activity',
		theme: 'navy',
		locale: ['en', 'de-DE', 'fr-FR', 'tr', 'sv', 'pl', 'pt-BR', 'it-IT', 'es-ES', 'es-419', 'da', 'nb', 'nl'],
		slug: 'male-activity',
	},
	FEMALE_ACTIVITY_FUNNEL_ONBOARDING_BUILDER: {
		id: 'ob-female-activity',
		theme: 'light',
		locale: ['en', 'de-DE', 'fr-FR', 'pt-BR', 'it-IT', 'es-ES', 'sv', 'pl', 'es-419', 'da', 'nb', 'nl'],
		slug: 'female-activity-v2',
	},

	FEMALE_ACTIVITY_PILATES_FUNNEL_ONBOARDING_BUILDER: {
		id: 'ob-yoga-narrative-on-pilates',
		theme: 'light',
		locale: ['en', 'de-DE', 'fr-FR', 'tr', 'sv', 'pl', 'pt-BR', 'it-IT', 'es-ES', 'es-419', 'da', 'nb', 'nl'],
		slug: 'activity-female-pilates',
	},

	MAIN_YOGA_FUNNEL_ONBOARDING_BUILDER: {
		id: 'ob-yoga-gender-selection',
		theme: 'light',
		locale: ['en'],
		slug: 'main-yoga',
		redirectTo: [
			{
				appArea: 'activity-male-yoga',
				routeName: 'male-activity-chair-yoga',
				condition: (ctx) => ctx.sex?.includes('male'),
			},
			{
				appArea: 'activity-female-yoga',
				routeName: 'female-activity-chair-yoga',
				condition: (ctx) => ctx.sex?.includes('female'),
			},
		],
	},
	MALE_ACTIVITY_CHAIR_YOGA_FUNNEL_ONBOARDING_BUILDER: {
		id: 'ob-male-activity-chair-yoga',
		theme: 'light',
		locale: ['en'],
		slug: 'male-activity-yoga',
	},
	FEMALE_ACTIVITY_CHAIR_YOGA_FUNNEL_ONBOARDING_BUILDER: {
		id: 'ob-female-activity-chair-yoga',
		theme: 'light',
		locale: ['en', 'de-DE', 'fr-FR', 'tr', 'sv', 'pl', 'pt-BR', 'it-IT', 'es-ES', 'es-419', 'da', 'nb', 'nl'],
		slug: 'female-activity-yoga',
	},

	FEMALE_ACTIVITY_SOMATIC_YOGA_FUNNEL_ONBOARDING_BUILDER: {
		id: 'ob-female-activity-somatic-yoga',
		theme: 'light',
		locale: ['en', 'de-DE', 'fr-FR', 'tr', 'sv', 'pl', 'pt-BR', 'it-IT', 'es-ES', 'es-419', 'da', 'nb', 'nl'],
		slug: 'female-activity-somatic-yoga',
	},

	MAIN_WALKING_FUNNEL_ONBOARDING_BUILDER: {
		id: 'ob-walking-gender-selection',
		theme: 'light',
		locale: ['en'],
		slug: 'main-walking',
		redirectTo: [
			{
				appArea: 'activity-male-walking',
				routeName: 'male-activity-walking',
				condition: (ctx) => ctx.sex?.includes('male'),
			},
			{
				appArea: 'activity-female-walking',
				routeName: 'female-activity-walking',
				condition: (ctx) => ctx.sex?.includes('female'),
			},
		],
	},
	FEMALE_ACTIVITY_WALKING_FUNNEL_ONBOARDING_BUILDER: {
		id: 'ob-female-activity-walking',
		theme: 'light',
		locale: ['en', 'de-DE', 'fr-FR', 'tr', 'sv', 'pl', 'pt-BR', 'it-IT', 'es-ES', 'es-419', 'da', 'nb', 'nl'],
		slug: 'female-activity-walking',
	},
	MALE_ACTIVITY_WALKING_FUNNEL_ONBOARDING_BUILDER: {
		id: 'ob-male-activity-walking',
		theme: 'light',
		locale: ['en', 'de-DE', 'fr-FR', 'tr', 'sv', 'pl', 'pt-BR', 'it-IT', 'es-ES', 'es-419', 'da', 'nb', 'nl'],
		slug: 'male-activity-walking',
	},
}

const FUNNELS = {
	MAIN_UNIVERSAL_FUNNEL: {
		id: 'ob-universal-funnel',
		slug: 'main',
		theme: 'light',
		locale: ['en', 'de-DE', 'fr-FR', 'tr', 'sv', 'pl', 'pt-BR', 'it-IT', 'es-ES', 'es-419', 'da', 'nb', 'nl'],
		redirectTo: [
			{
				appArea: 'male',
				routeName: 'male-survey',
				condition: (ctx) => ctx.sex?.includes('male'),
			},
			{
				appArea: 'female',
				routeName: 'female-survey',
				condition: (ctx) => ctx.sex?.includes('female'),
			},
		],
	},

	MALE_FUNNEL_ONBOARDING_BUILDER: {
		id: 'ob-male-funnel',
		theme: 'navy',
		locale: ['en', 'de-DE', 'fr-FR', 'tr', 'sv', 'pl', 'pt-BR', 'it-IT', 'es-ES', 'es-419', 'da', 'nb', 'nl'],
		slug: 'male',
	},

	FEMALE_FUNNEL_ONBOARDING_BUILDER: {
		id: 'ob-female-funnel',
		theme: 'light',
		locale: ['en', 'de-DE', 'fr-FR', 'tr', 'sv', 'pl', 'pt-BR', 'it-IT', 'es-ES', 'es-419', 'da', 'nb', 'nl'],
		slug: 'female',
	},

	FEMALE_FUNNEL_EMOTIONAL_NARRATIVE: {
		id: 'ob-female-emotional-narrative',
		theme: 'light',
		locale: ['en'],
		slug: 'female',
	},

	...ACTIVITY_FUNNELS,
	...EXPERIMENTAL_FLOW_FUNNELS,
}

const SERVICE_PREFIXES = {
	'/account': {
		appArea: 'account',
	},
	'/redirect': {
		appArea: 'redirect',
	},
}

/**
 * Generate regex from domain name to allow for wildcard subdomains
 * @param funnelsConfig
 * @return {{domainConfig: *, regex: RegExp}[]}
 */
function generateRegexFromFunnelsConfig(funnelsConfig) {
	const domains = Object.keys(funnelsConfig)
	const result = []
	for (const domain of domains) {
		result.push({
			domainConfig: { ...funnelsConfig[domain], ...SERVICE_PREFIXES },
			regex: new RegExp(`^${domain.replace(/^\*/, '.*')}$`),
		})
	}
	return result
}

const domainsFromConfig = generateRegexFromFunnelsConfig(FunnelsConfig)

module.exports = {
	FUNNELS,
	SERVICE_PREFIXES,
	domainsFromConfig,
}
