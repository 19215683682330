import config from '@/config'

export default {
	id: 'SIMPLE_UPGRADE_3M_47_2',
	providers: {
		stripe: config('stripe_cheap_and_short_upgrade_3_month'),
		braintree: 'pro_short_cheap_upgrade',
		paltaMap: {
			USD: 'simple_premium_3m_47_2_usd',
			EUR: 'simple_premium_3m_47_2_eur',
			GBP: 'simple_premium_3m_47_2_gbp',
			AUD: 'simple_premium_3m_71_aud',
			CAD: 'simple_premium_3m_71_2_cad',
			DKK: 'simple_premium_3m_351_2_dkk',
			SEK: 'simple_premium_3m_559_2_sek',
			NOK: 'simple_premium_3m_559_2_nok',
			BRL: 'simple_premium_3m_231_2_brl',
			MXN: 'simple_premium_3m_783_2_mxn',
			TRY: 'simple_premium_3m_1519_try',
		},
	},
	trial: null,
	subscription: {
		interval: 'month',
		intervalCount: 3,
		priceMap: {
			USD: 47.2,
			EUR: 47.2,
			GBP: 47.2,
			AUD: 71.2,
			CAD: 71.2,
			DKK: 351.2,
			SEK: 559.2,
			NOK: 559.2,
			BRL: 231.2,
			MXN: 783.2,
			TRY: 1519,
		},
	},
	options: () => ({}),
}
