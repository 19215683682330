import LandingSwap from '@/components/LandingSwap.vue'

export default [
	// FEMALE
	{
		path: '/pilates-f',
		name: 'female-pilates-funnel',
		component: LandingSwap,
		meta: {
			isLanding: true,
		},
	},
	{
		path: '/pilates-f/survey/ob/:page*',
		name: 'female-activity-pilates',
		component: () => import(/* webpackChunkName: "ob-survey" */ '@/modules/Onboarding/OnboardingView.vue'),
	},
]
