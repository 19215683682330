import { mapGetters, mapState } from 'vuex'

export default {
	computed: {
		...mapState({
			subscription: 'subscription',
		}),
		...mapGetters({
			language: 'i18n/getLanguage',
			accountPlatform: 'location/accountPlatform',
			getQueryParam: 'location/getQueryParam',
			userBmi: 'getBmi',
			countryCode: 'getCountryCode',
			getPropertyValue: 'survey/getPropertyValue',
			commitmentPlanPeriod: 'getCommitmentPlanPeriod',
			appTheme: 'getAppTheme',
			appArea: 'getAppArea',
			isOrganicTrafficOnboarding: 'userFlags/getIsOrganicTrafficOnboarding',
			FEAT_GOOGLE_SEARCH_UTM_CAMPAIGNS: 'coreFeatures/FEAT_GOOGLE_SEARCH_UTM_CAMPAIGNS',
			FEAT_CONFIRM_EMAIL_BY_CODE: 'coreFeatures/FEAT_CONFIRM_EMAIL_BY_CODE',
		}),
		IS_ACCOUNT_DELETION_DEEPLINK_EXP() {
			return this.getQueryParam('exp') === 'ios_improve_account_deletion'
		},
		IS_ACCOUNT_DELETION_DEEPLINK_V2_EXP() {
			return this.getQueryParam('exp') === 'ios_improve_account_deletion_v2'
		},
		IS_AUTH0_ACCOUNT_DELETION_DEEPLINK_ACCOUNT_DELETION() {
			return this.getQueryParam('exp') === 'auth0_rollout_deletion'
		},
		IS_AUTH0_ACCOUNT_DELETION_DEEPLINK_EXP_MANAGE_SUBS() {
			return this.getQueryParam('exp') === 'auth0_rollout_manage_subscription'
		},
		IS_CONFIRM_EMAIL_BY_CODE_EXP() {
			return (this.accountPlatform.ios || this.accountPlatform.android) && this.FEAT_CONFIRM_EMAIL_BY_CODE
		},
		IS_NEW_ACCOUNT_PAGE_EXP() {
			return (
				this.accountPlatform.ios &&
				Boolean(!this.commitmentPlanPeriod) &&
				this.$absmartly.treatment('web_new_account_page')
			)
		},
		IS_COMMITMENT_PLANS_MALE_EXP() {
			return this.$absmartly.treatment('web_male_commitment_plans')
		},
		IS_COMMITMENT_PLANS_ACTIVITY_EXP() {
			return [
				this.$absmartly.treatment('web_activity_commitment_plans'),
				this.$absmartly.treatment('web_pilates_commitment_plans'),
				this.$absmartly.treatment('web_yoga_commitment_plans'),
				this.$absmartly.treatment('web_somatic_yoga_commitment_plans'),
				this.$absmartly.treatment('web_walking_commitment_plans'),
				this.$absmartly.treatment('web_activity_commitment_plans_nonus'),
				this.$absmartly.treatment('web_pilates_commitment_plans_nonus'),
				this.$absmartly.treatment('web_yoga_commitment_plans_nonus'),
				this.$absmartly.treatment('web_somatic_yoga_commitment_plans_nonus'),
			].some(Boolean)
		},
		IS_CRO_BULK_EXP() {
			return this.$absmartly.treatment('web_cro_bulk_v2')
		},
		IS_HIDE_ZIPCODE_FIELD() {
			return this.$absmartly.treatment('web_hide_zipcode_field')
		},
		IS_PRE_PAYWALL_FOR_BETTER_MONETIZATION_EXP() {
			return this.$absmartly.treatment('web_pre_paywall_for_better_monetization')
		},
		IS_APPLE_PAY_AS_DEFAULT_CTA_EXP() {
			return this.$absmartly.treatment('web_apple_pay_as_default_cta')
		},
		IS_FEMALE_50_PAYWALL_OPTIMIZATION_EXP() {
			const age = this.getPropertyValue('age', true)
			if (age < 50) {
				return false
			}
			return this.$absmartly.treatment('web_female_50_paywall_optimization')
		},
		IS_MALE_PAYWALL_OPTIMIZATION_EXP() {
			return this.$absmartly.treatment('web_male_paywall_optimization')
		},
		IS_AS_FEATURED_IN_PAYWALL_EXP() {
			return this.$absmartly.treatment('web_as_featured_in_paywall')
		},
		IS_FEMALE_ACTIVITY_BEFORE_PREDICT_EXP() {
			return this.$absmartly.treatment('web_female_activity_before_predict_ob_funnel')
		},
		IS_POST_PURCHASE_OPTIMIZATION_EXP() {
			return ['main', 'female', 'aff-main', 'aff-female', 'male', 'aff-male'].includes(this.appArea)
		},
		IS_POST_PURCHASE_OPTIMIZATION_i2_EXP() {
			return this.$absmartly.treatment('web_post_purchase_optimization')
		},
		IS_LOADER_LIKE_STORIES_FEMALE_EXP() {
			return this.$absmartly.treatment('web_loader_like_stories_female')
		},
		IS_FEMALE_ACTIVITY_NEW_UPGRADES_EXP() {
			if (this.IS_COMMITMENT_PLANS_ACTIVITY_EXP) {
				return false
			}

			return [
				!this.isOrganicTrafficOnboarding && this.IS_MALE_ACTIVITY_OB_FUNNEL,
				!this.isOrganicTrafficOnboarding && this.IS_FEMALE_ACTIVITY_OB_FUNNEL,
				!this.isOrganicTrafficOnboarding && this.IS_FEMALE_ACTIVITY_CHAIR_YOGA_OB_FUNNEL,
				!this.isOrganicTrafficOnboarding && this.IS_FEMALE_ACTIVITY_PILATES_OB_FUNNEL && this.countryCode !== 'us',
				!this.isOrganicTrafficOnboarding && this.IS_MALE_ACTIVITY_WALKING_OB_FUNNEL,
				!this.isOrganicTrafficOnboarding && this.IS_MALE_ACTIVITY_CHAIR_YOGA_OB_FUNNEL,
			].some(Boolean)
		},
		IS_NEW_AGE_SCREEN_EXP() {
			return this.$absmartly.treatment('web_new_age_screen')
		},
		IS_NEW_CANCELLATION_OFFER_AND_UI_EXP() {
			return !Boolean(this.commitmentPlanPeriod) && this.$absmartly.treatment('web_new_cancellation_offer_and_ui')
		},
		IS_MALE_NEW_WORKBOOKS_EXP() {
			return this.$absmartly.treatment('web_male_new_workbooks')
		},
		IS_COMMITMENT_DELAY_VS_FEES_EXP() {
			return Boolean(this.commitmentPlanPeriod) && this.$absmartly.treatment('web_commitment_delay_vs_fees')
		},
		IS_FULL_BODY_REPORT_EXP() {
			return this.IS_FEMALE_ACTIVITY_OB_FUNNEL && this.countryCode === 'us'
		},
		IS_MALE_PAYWALL_OPTIMIZATION_HOTJAR_EXP() {
			return this.$absmartly.treatment('web_male_paywall_optimization_hotjar_track')
		},
		IS_MALE_FUNNEL_HOTJAR_EXP() {
			return this.$absmartly.treatment('web_male_funnel_hotjar_track')
		},
		IS_FEMALE_FUNNEL_HOTJAR_EXP() {
			return this.$absmartly.treatment('web_female_funnel_hotjar_track')
		},
		IS_ACTIVITY_WELCOME_FOR_GOOGLE_EXP() {
			return this.$absmartly.treatment('web_activity_welcome_for_google')
		},
		IS_ACTIVITY_UPDATE_MONEYBACK_GUARANTEE_EXP() {
			return [
				this.$absmartly.treatment('web_activity_update_moneyback_guarantee'),
				this.$absmartly.treatment('web_yoga_update_moneyback_guarantee'),
				this.$absmartly.treatment('web_pilates_update_moneyback_guarantee'),
			].some(Boolean)
		},
		IS_CANCELLATION_FLOW_CA_EXP() {
			return (
				this.getQueryParam('utm_source') === 'simple_landing' &&
				this.getQueryParam('utm_medium') === 'manage_subscription_ca'
			)
		},
		IS_NEW_CANCELLATION_UI() {
			return (
				(this.IS_CANCELLATION_FLOW_CA_EXP || this.IS_NEW_CANCELLATION_OFFER_AND_UI_EXP) &&
				!Boolean(this.commitmentPlanPeriod)
			)
		},
		IS_COMMITMENT_PLANS_FRACTIONAL_EXP() {
			return this.$absmartly.treatment('web_commitment_plans_fractional')
		},
		IS_FORBES_ON_FIRST_LOADER_EXP() {
			return this.$absmartly.treatment('web_forbes_on_first_loader')
		},
		IS_TOP_OF_THE_FUNNEL_OPTIMIZATION_EXP() {
			return this.$absmartly.treatment('web_top_funnel_optimization')
		},
		IS_FEMALE_EMOTIONAL_NARRATIVE_EXP() {
			return this.$absmartly.treatment('web_female_emotional_narrative')
		},
		IS_MENOPAUSE_FUNNEL_OPTIMIZATION_EXP() {
			const age = this.getPropertyValue('age', true)
			if (age < 50) {
				return false
			}
			return this.$absmartly.treatment('web_menopause_funnel_optimization')
		},
		IS_WALKING_NEW_PAYWALL_EXP() {
			return this.IS_FEMALE_ACTIVITY_WALKING_OB_FUNNEL && this.countryCode === 'us'
		},
		IS_GOOGLE_BMI_CAMPAIGN_EXP() {
			return this.FEAT_GOOGLE_SEARCH_UTM_CAMPAIGNS.bmiCalculator?.includes(this.getQueryParam('utm_campaign'))
		},
		IS_GOOGLE_CALORIE_DEFICIT_CAMPAIGN_EXP() {
			return this.FEAT_GOOGLE_SEARCH_UTM_CAMPAIGNS.calorieDeficit?.includes(this.getQueryParam('utm_campaign'))
		},
		IS_GOOGLE_FASTING_CAMPAIGN_EXP() {
			return this.FEAT_GOOGLE_SEARCH_UTM_CAMPAIGNS.fasting?.includes(this.getQueryParam('utm_campaign'))
		},
		IS_GOOGLE_GOAL_CAMPAIGN_EXP() {
			return this.FEAT_GOOGLE_SEARCH_UTM_CAMPAIGNS.weightLoss?.includes(this.getQueryParam('utm_campaign'))
		},
		IS_GOOGLE_MEAL_PLAN_CAMPAIGN_EXP() {
			return this.FEAT_GOOGLE_SEARCH_UTM_CAMPAIGNS.dietAndMealPlan?.includes(this.getQueryParam('utm_campaign'))
		},
		IS_WALKING_NEW_UPSELLS_EXP() {
			return this.$absmartly.treatment('web_walking_new_upsells')
		},
		IS_ACTIVITY_FACE_MASSAGE_UPSELL_EXP() {
			return this.$absmartly.treatment('web_activity_face_massage_upsell_exp')
		},
		IS_YOGA_FACE_MASSAGE_UPSELL_EXP() {
			return this.$absmartly.treatment('web_yoga_face_massage_upsell_exp')
		},
		IS_PILATES_FACE_MASSAGE_UPSELL_EXP() {
			return this.$absmartly.treatment('web_pilates_face_massage_upsell_exp')
		},
		IS_ACTIVITY_OPTIMIZATION_FOR_CTA_EXP() {
			return this.$absmartly.treatment('web_activity_optimization_for_cta_exp')
		},
		IS_REDUCTION_LOADING_MALE_EXP() {
			return this.$absmartly.treatment('web_reduction_loading_male')
		},
		IS_PERSONALIZED_PROMOCODE_EXP() {
			return (
				['activity-female-v2', 'female', 'activity-female-pilates'].includes(this.appArea) &&
				this.$absmartly.treatment('web_personalized_promocode')
			)
		},
		IS_REDUCTION_LOADING_FEMALE_EXP() {
			return this.$absmartly.treatment('web_reduction_loading_female')
		},
		IS_FEMALE_CHAIR_YOGA_OPTIMIZATION_FOR_CTA_EXP() {
			return this.$absmartly.treatment('web_female_yoga_optimization_for_cta')
		},
		IS_MALE_WALKING_PAYWALL_OPTIMIZATION_EXP() {
			return this.$absmartly.treatment('web_male_walking_paywall_optimization')
		},
		IS_FEMALE_CHAIR_YOGA_EVENT_PERSONALIZATION_EXP() {
			return this.$absmartly.treatment('web_female_chair_yoga_event_personalization')
		},
	},
}
