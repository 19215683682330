export default {
	id: 'SIMPLE_UPGRADE_9M_89_97',
	providers: {
		stripe: '',
		braintree: '',
		paltaMap: {
			USD: 'simple_premium_9m_89_97_usd',
			EUR: 'simple_premium_9m_89_97_eur',
			GBP: 'simple_premium_9m_89_97_gbp',
			AUD: 'simple_premium_9m_129_aud',
			CAD: 'simple_premium_9m_129_cad',
			SEK: 'simple_premium_9m_1119_sek',
			BRL: 'simple_premium_9m_429_brl',
			DKK: 'simple_premium_9m_649_dkk',
			NOK: 'simple_premium_9m_1119_nok',
			MXN: 'simple_premium_9m_1559_mxn',
			TRY: 'simple_premium_9m_3099_try',
		},
	},
	trial: null,
	subscription: {
		interval: 'month',
		intervalCount: 9,
		priceMap: {
			USD: 89.97,
			EUR: 89.97,
			GBP: 89.97,
			AUD: 129,
			CAD: 129,
			SEK: 1119,
			BRL: 429,
			DKK: 649,
			NOK: 1119,
			MXN: 1559,
			TRY: 3099,
		},
	},
	options: ($t) => ({
		currentSubscriptionRateText: ({ trialPrice, price }) => {
			return $t('activity_upgrade.9mo.subtitle_1', { trialPrice, price })
		},
		upgradeOfferText: ({ price }) => {
			return $t('activity_upgrade.9mo.subtitle_2', { price })
		},
		barChartTooltip: $t('activity_upgrade.9mo.bar_chart_tooltip'),
		barChartLeftLabel: $t('activity_upgrade.9mo.bar_chart_left_label'),
		barChartRightLabel: $t('activity_upgrade.9mo.bar_chart_right_label'),
		upgradeRate: $t('activity_upgrade.9mo.upgrade_rate'),
		currentPriceLabel: $t('activity_upgrade.9mo.current_price_label'),
		offerPriceLabel: $t('activity_upgrade.9mo.offer_price_label'),
	}),
}
