export default {
	id: 'SIMPLE_PAYWALL_1M_29_99_6M_12_99',
	providers: {
		paltaMap: {
			USD: 'simple_premium_comm_1m_29_99_6m_12_99_usd',
		},
	},
	trial: {
		days: 30,
		priceMap: {
			USD: 12.99,
		},
		originalPriceMap: {
			USD: 29.99,
		},
	},
	subscription: {
		interval: 'month',
		intervalCount: 1,
		priceMap: {
			USD: 29.99,
		},
	},
	options: ($t) => ({
		commitment: true,
		commitmentPeriodMonths: 6,
		explicitTermsPeriodText: $t('pages.program.organic.cheap_and_short.plans.30_days.into_period_text'), // 'first month'
		explicitTermsIntervalText: $t('pages.program.organic.cheap_and_short.plans.30_days.sub_period_text'), // 'monthly'
		billingSubtitlePeriodText: $t('pages.program.cheap_and_short.plans.30_days.into_period_text'), // 'first month'
		footerPeriodText: $t('pages.special_upsale.upgrade_durations.6'), // 6-month
		footerIntervalText: $t('pages.program.cheap_and_short.plans.30_days.sub_period_text'), // 'monthly'
	}),
}
