import { mapGetters } from 'vuex'

export default {
	computed: {
		...mapGetters({
			FEAT_FUNNELS_PRELOAD: 'coreFeatures/FEAT_FUNNELS_PRELOAD',
		}),
	},
	methods: {
		preloadFunnelResources(nextRoute) {
			const preloadFunnelDelay = this.FEAT_FUNNELS_PRELOAD[nextRoute]

			if (!preloadFunnelDelay || typeof preloadFunnelDelay !== 'number') {
				return
			}

			setTimeout(() => {
				const preloadComponent = this.$router.resolve({ name: nextRoute }).route?.matched[0]?.components?.default

				if (!preloadComponent || typeof preloadComponent !== 'function') {
					return
				}

				preloadComponent()
			}, preloadFunnelDelay)
		},
	},
}
