import LandingSwap from '@/components/LandingSwap.vue'

export default [
	{
		path: '/somatic-yoga',
		name: 'somatic-yoga-funnel',
		component: LandingSwap,
		meta: {
			isLanding: true,
		},
	},

	{
		path: '/somatic-yoga/survey/ob/:page*',
		name: 'female-activity-somatic-yoga',
		component: () => import(/* webpackChunkName: "ob-survey" */ '@/modules/Onboarding/OnboardingView.vue'),
	},
]
