import { render, staticRenderFns } from "./PaltaCheckout.vue?vue&type=template&id=4b38a477&scoped=true"
import script from "./PaltaCheckout.vue?vue&type=script&lang=js"
export * from "./PaltaCheckout.vue?vue&type=script&lang=js"
import style0 from "./PaltaCheckout.vue?vue&type=style&index=0&id=4b38a477&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b38a477",
  null
  
)

export default component.exports