export default {
	id: 'SIMPLE_UPGRADE_6M_77_95',
	providers: {
		stripe: '',
		braintree: '',
		paltaMap: {
			USD: 'simple_premium_6m_77_95_usd',
			EUR: 'simple_premium_6m_77_95_eur',
			GBP: 'simple_premium_6m_77_95_gbp',
			AUD: 'simple_premium_6m_109_aud',
			CAD: 'simple_premium_6m_109_cad',
			SEK: 'simple_premium_6m_929_sek',
			BRL: 'simple_premium_6m_389_brl',
			DKK: 'simple_premium_6m_569_dkk',
			NOK: 'simple_premium_6m_929_nok',
			MXN: 'simple_premium_6m_1259_mxn',
			TRY: 'simple_premium_6m_2699_try',
		},
	},
	trial: null,
	subscription: {
		interval: 'month',
		intervalCount: 6,
		priceMap: {
			USD: 77.95,
			EUR: 77.95,
			GBP: 77.95,
			AUD: 109,
			CAD: 109,
			SEK: 929,
			BRL: 389,
			DKK: 569,
			NOK: 929,
			MXN: 1259,
			TRY: 2699,
		},
	},
	options: ($t) => ({
		currentSubscriptionRateText: ({ trialPrice, price }) => {
			return $t('activity_upgrade.6mo.subtitle_1', { trialPrice, price })
		},
		upgradeOfferText: ({ price }) => {
			return $t('activity_upgrade.6mo.subtitle_2', { price })
		},
		barChartTooltip: $t('activity_upgrade.6mo.bar_chart_tooltip'),
		barChartLeftLabel: $t('activity_upgrade.6mo.bar_chart_left_label'),
		barChartRightLabel: $t('activity_upgrade.6mo.bar_chart_right_label'),
		upgradeRate: $t('activity_upgrade.6mo.upgrade_rate'),
		currentPriceLabel: $t('activity_upgrade.6mo.current_price_label'),
		offerPriceLabel: $t('activity_upgrade.6mo.offer_price_label'),
	}),
}
