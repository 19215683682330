export default {
	id: 'SIMPLE_PREMIUM_1M_30',
	providers: {
		paltaMap: {
			USD: 'simple_premium_1m_30_usd',
			EUR: 'simple_premium_1m_29_99_1m_30_eur',
			GBP: 'simple_premium_1m_30_gbp',
			CAD: 'simple_premium_1m_50_cad',
			AUD: 'simple_premium_1m_50_aud',
			DKK: 'simple_premium_v2_1m_259_1m_259_dkk',
			SEK: 'simple_premium_v2_1m_399_1m_399_sek',
			NOK: 'simple_premium_v2_1m_399_1m_399_nok',
			BRL: 'simple_premium_1m_199_90_1m_199_90_brl',
			MXN: 'simple_premium_v2_1m_499_1m_499_mxn',
			TRY: 'simple_premium_1m_1000_try',
		},
	},
	trial: {
		days: 30,
		priceMap: {
			USD: 30,
			EUR: 30,
			GBP: 30,
			CAD: 50,
			AUD: 50,
			DKK: 259,
			SEK: 399,
			NOK: 399,
			BRL: 199.9,
			MXN: 499,
			TRY: 1000,
		},
		originalPriceMap: {
			USD: 30,
			EUR: 30,
			GBP: 30,
			CAD: 50,
			AUD: 50,
			DKK: 259,
			SEK: 399,
			NOK: 399,
			BRL: 199.9,
			MXN: 499,
			TRY: 1000,
		},
	},
	subscription: {
		interval: 'month',
		intervalCount: 1,
		priceMap: {
			USD: 30,
			EUR: 30,
			GBP: 30,
			CAD: 50,
			AUD: 50,
			DKK: 259,
			SEK: 399,
			NOK: 399,
			BRL: 199.9,
			MXN: 499,
			TRY: 1000,
		},
	},
	options: ($t) => ({
		explicitTermsPeriodText: $t('pages.program.organic.cheap_and_short.plans.30_days.into_period_text'), // 'first month'
		explicitTermsIntervalText: $t('pages.program.organic.cheap_and_short.plans.30_days.sub_period_text'), // 'monthly'
		billingSubtitlePeriodText: $t('pages.program.cheap_and_short.plans.30_days.into_period_text'), // 'first month'
		footerPeriodText: $t('pages.program.cheap_and_short.plans.30_days.period_text'), // '1-month'
		footerIntervalText: $t('pages.program.cheap_and_short.plans.30_days.sub_period_text'), // 'monthly'
	}),
}
