import { fstrRequest, fstrSyncRequest, fstrUsersRequest } from '@/helpers/request'

const api = {
	async getUser() {
		return await fstrRequest('user', { timeout: 10000 })
	},

	async updateUser(data) {
		return await fstrRequest('user', { method: 'put', data })
	},

	async generateLoginCode() {
		return await fstrRequest('user/login/code/generate', { method: 'post' })
	},

	async loginByCode(data) {
		return await fstrRequest('user/login/code', { method: 'post', data })
	},

	async sendConfirmEmail(data) {
		return await fstrRequest('user/login/code/send', { method: 'post', data })
	},

	async createMeasurement(data) {
		return await fstrRequest('user/measurements', { method: 'post', data })
	},

	async userCheck(data) {
		return await fstrRequest('user/check', { method: 'post', data })
	},

	async getUserFlags() {
		return await fstrRequest('user/flags', { method: 'get' })
	},

	async setUserFlags(data) {
		return await fstrRequest('user/flags', { method: 'post', data })
	},

	async healthCoachLoginByCode(data) {
		return await fstrRequest('user/login/code/healthcoach/send', { method: 'post', data })
	},

	async saveFunnelTags(data) {
		return await fstrUsersRequest('user/tags', { method: 'post', data })
	},

	async mergeUserWithAlreadyExisted() {
		return await fstrUsersRequest('user/merge-link/resolve', { method: 'post' })
	},

	async saveBrazeAttributes(data) {
		return await fstrSyncRequest('user-attributes/track', { method: 'post', data })
	},
}

export default api
