import config from '@/config'

export default {
	id: 'SIMPLE_PAYWALL_1M_29_99_1W_9_99',
	providers: {
		stripe: config('stripe_cheap_and_short'),
		braintree: 'pro_short_cheap',
		paltaMap: {
			USD: 'simple_premium_1m_29_99_1w_9_99_usd',
			EUR: 'simple_premium_1m_29_99_1w_9_99_eur',
			GBP: 'simple_premium_1m_29_99_1w_9_99_gbp',
			AUD: 'simple_premium_1m_49_99_1w_15_99_aud',
			CAD: 'simple_premium_1m_49_99_1w_15_99_cad',
			DKK: 'simple_premium_1m_259_1w_79_dkk',
			SEK: 'simple_premium_1m_399_1w_119_sek',
			NOK: 'simple_premium_1m_399_1w_119_nok',
			BRL: 'simple_premium_1m_199_99_1w_49_99_brl',
			MXN: 'simple_premium_1m_499_1w_169_mxn',
			TRY: 'simple_premium_1m_999_1w_299_try',
		},
	},
	trial: {
		days: 7,
		priceMap: {
			USD: 9.99,
			EUR: 9.99,
			GBP: 9.99,
			AUD: 15.99,
			CAD: 15.99,
			DKK: 79,
			SEK: 119,
			NOK: 119,
			BRL: 49.99,
			MXN: 169,
			TRY: 299,
		},
	},
	subscription: {
		interval: 'month',
		intervalCount: 1,
		priceMap: {
			USD: 29.99,
			EUR: 29.99,
			GBP: 29.99,
			AUD: 49.99,
			CAD: 49.99,
			DKK: 259,
			SEK: 399,
			NOK: 399,
			BRL: 199.99,
			MXN: 499,
			TRY: 999,
		},
	},
	options: ($t) => ({
		explicitTermsPeriodText: $t('pages.program.organic.cheap_and_short.plans.7_days.into_period_text'), // 'first week'
		explicitTermsIntervalText: $t('pages.program.organic.cheap_and_short.plans.7_days.sub_period_text'), // 'monthly'
		billingSubtitlePeriodText: $t('pages.program.cheap_and_short.plans.7_days.into_period_text'), // 'first week'
		footerPeriodText: $t('pages.program.cheap_and_short.plans.7_days.period_text'), // '1-week'
		footerIntervalText: $t('pages.program.cheap_and_short.plans.7_days.sub_period_text'), // 'monthly'
	}),
}
