export default [
	{
		path: '/women',
		redirect: '/f',
	},
	{
		path: '/men',
		redirect: '/m',
	},
	{
		path: '/active-women',
		redirect: '/activity-f',
	},
	{
		path: '/women-pilates',
		redirect: '/pilates-f',
	},
	{
		path: '/women-yoga',
		redirect: '/yoga-f',
	},
	{
		path: '/age-input',
		redirect: '/a-input',
	},
]
