import LandingSwap from '@/components/LandingSwap.vue'

export default [
	// GENDER SELECTION
	{
		path: '/yoga',
		name: 'main-yoga-funnel',
		component: LandingSwap,
		meta: {
			isLanding: true,
		},
	},
	{
		path: '/yoga/survey/ob/:page*',
		name: 'yoga-survey',
		component: () => import(/* webpackChunkName: "ob-survey" */ '@/modules/Onboarding/OnboardingView.vue'),
	},

	// FEMALE
	{
		path: '/yoga-f',
		name: 'female-yoga-funnel',
		component: LandingSwap,
		meta: {
			isLanding: true,
		},
	},
	{
		path: '/yoga-f/survey/ob/:page*',
		name: 'female-activity-chair-yoga',
		component: () => import(/* webpackChunkName: "ob-survey" */ '@/modules/Onboarding/OnboardingView.vue'),
	},

	// MALE
	{
		path: '/yoga-m',
		name: 'male-yoga-funnel',
		component: LandingSwap,
		meta: {
			isLanding: true,
		},
	},
	{
		path: '/yoga-m/survey/ob/:page*',
		name: 'male-activity-chair-yoga',
		component: () => import(/* webpackChunkName: "ob-survey" */ '@/modules/Onboarding/OnboardingView.vue'),
	},
]
