export default [
	{
		path: 'main',
		name: 'account-main',
		component: () => import(/* webpackChunkName: "account-main" */ '@/modules/Account/AccountView.vue'),
		meta: {
			requiresAuth: true,
			requiresSubscription: true,
			requiresUser: true,
		},
	},
]
