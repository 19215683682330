import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from '../store'
import config from '../config'
import pluralizationRules from './plural.js'
import request from '@/helpers/request'
import Sentry from '@/modules/Sentry'

Vue.use(VueI18n)

export const i18n = new VueI18n({
	locale: '', // set locale
	fallbackLocale: 'en',
	messages: {},
	pluralizationRules,
})

export async function loadLanguageAsync() {
	const queryParams = new URLSearchParams(window.location.search)
	const queryParamLang = queryParams.get('lang')?.toLocaleLowerCase() ?? store.getters['location/getQueryParam']('lang')

	const availableLanguages = config('SupportedLanguages')

	if (queryParamLang && availableLanguages.includes(queryParamLang)) {
		window.language = queryParamLang
	}

	const lang = window.language
	document.documentElement.setAttribute('lang', lang)

	if (i18n.locale === lang) {
		return
	}

	try {
		const response = await request(`${config('ApiLocalization')}${lang}/web.json`)

		i18n.setLocaleMessage(lang, response)
		i18n.locale = lang
		store.commit('i18n/setLanguage', lang)
		store.commit('status/setResourceReadiness', 'i18n')

		document.title = i18n.t('common.main_pagetitle')
	} catch (e) {
		if (e.name !== 'AxiosError') {
			Sentry.captureException(e)
		}
	}
}
