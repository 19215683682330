import LandingSwap from '@/components/LandingSwap.vue'

export default [
	// GENDER SELECTION
	{
		path: '/activity-survey/:page*',
		name: 'activity-survey',
		component: () => import(/* webpackChunkName: "ob-survey" */ '@/modules/Onboarding/OnboardingView.vue'),
	},

	// FEMALE
	{
		path: '/activity-f',
		name: 'female-activity-funnel',
		component: LandingSwap,
		meta: {
			isLanding: true,
		},
	},
	{
		path: '/activity-f/survey/ob/:page*',
		name: 'female-activity-onboarding',
		component: () => import(/* webpackChunkName: "ob-survey" */ '@/modules/Onboarding/OnboardingView.vue'),
	},

	// MALE
	{
		path: '/activity-m',
		name: 'male-activity-funnel',
		component: LandingSwap,
		meta: {
			isLanding: true,
		},
	},
	{
		path: '/activity-m/survey/:page*',
		name: 'male-activity-onboarding',
		component: () => import(/* webpackChunkName: "ob-survey" */ '@/modules/Onboarding/OnboardingView.vue'),
	},
]
