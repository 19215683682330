export default {
	id: 'SIMPLE_UPGRADE_9M_79_95',
	providers: {
		stripe: '',
		braintree: '',
		paltaMap: {
			USD: 'simple_premium_9m_79_95_usd',
			EUR: 'simple_premium_9m_79_95_eur',
			GBP: 'simple_premium_9m_79_95_gbp',
			AUD: 'simple_premium_9m_119_aud',
			CAD: 'simple_premium_9m_119_cad',
			SEK: 'simple_premium_9m_939_sek',
			BRL: 'simple_premium_9m_399_brl',
			DKK: 'simple_premium_9m_589_dkk',
			NOK: 'simple_premium_9m_939_nok',
			MXN: 'simple_premium_9m_1319_mxn',
			TRY: 'simple_premium_9m_2699_try',
		},
	},
	trial: null,
	subscription: {
		interval: 'month',
		intervalCount: 9,
		priceMap: {
			USD: 79.95,
			EUR: 79.95,
			GBP: 79.95,
			AUD: 119,
			CAD: 119,
			SEK: 939,
			BRL: 399,
			DKK: 589,
			NOK: 939,
			MXN: 1319,
			TRY: 2699,
		},
	},
	options: ($t) => ({
		currentSubscriptionRateText: ({ trialPrice, price }) => {
			return $t('activity_upgrade.9mo.subtitle_1', { trialPrice, price })
		},
		upgradeOfferText: ({ price }) => {
			return $t('activity_upgrade.9mo.subtitle_2', { price })
		},
		barChartTooltip: $t('activity_upgrade.9mo.bar_chart_tooltip'),
		barChartLeftLabel: $t('activity_upgrade.9mo.bar_chart_left_label'),
		barChartRightLabel: $t('activity_upgrade.9mo.bar_chart_right_label'),
		upgradeRate: $t('activity_upgrade.9mo.upgrade_rate'),
		currentPriceLabel: $t('activity_upgrade.9mo.current_price_label'),
		offerPriceLabel: $t('activity_upgrade.9mo.offer_price_label'),
	}),
}
