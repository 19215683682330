import { mapGetters } from 'vuex'
import { FUNNELS } from '@/data/funnels'

export default {
	computed: {
		...mapGetters({
			funnelType: 'location/getFunnelType',
			appTheme: 'getAppTheme',
		}),
		IS_DARK_THEME() {
			return ['dark', 'navy'].includes(this.appTheme)
		},
		MAIN_FUNNEL_ROUTE_NAME() {
			return 'survey'
		},
		IS_MAIN_FUNNEL() {
			return [FUNNELS.MAIN_UNIVERSAL_FUNNEL.id].includes(this.funnelType.id)
		},
		IS_EMAIL_BEFORE_PREDICT_FUNNEL() {
			if (this.IS_FEMALE_ACTIVITY_BEFORE_PREDICT_EXP) {
				return true
			}
			return [
				FUNNELS.MALE_FUNNEL_ONBOARDING_BUILDER.id,
				FUNNELS.FEMALE_FUNNEL_ONBOARDING_BUILDER.id,
				FUNNELS.FEMALE_FUNNEL_EVENT_PERSONALIZATION.id,
			].includes(this.funnelType.id)
		},
		IS_MALE_FUNNEL() {
			return [
				FUNNELS.MALE_FUNNEL_ONBOARDING_BUILDER.id,
				FUNNELS.MALE_ACTIVITY_FUNNEL_ONBOARDING_BUILDER.id,
				FUNNELS.MALE_ACTIVITY_WALKING_FUNNEL_ONBOARDING_BUILDER.id,
				FUNNELS.MALE_ACTIVITY_CHAIR_YOGA_FUNNEL_ONBOARDING_BUILDER.id,
			].includes(this.funnelType.id)
		},
		IS_MALE_OB_FUNNEL() {
			return [FUNNELS.MALE_FUNNEL_ONBOARDING_BUILDER.id].includes(this.funnelType.id)
		},
		IS_FEMALE_FUNNEL() {
			return [
				FUNNELS.FEMALE_FUNNEL_ONBOARDING_BUILDER.id,
				FUNNELS.FEMALE_ACTIVITY_FUNNEL_ONBOARDING_BUILDER.id,
				FUNNELS.FEMALE_ACTIVITY_FUNNEL_ONBOARDING_BUILDER_CRO_V1.id,
				FUNNELS.FEMALE_ACTIVITY_PILATES_FUNNEL_ONBOARDING_BUILDER.id,
				FUNNELS.FEMALE_ACTIVITY_CHAIR_YOGA_FUNNEL_ONBOARDING_BUILDER.id,
				FUNNELS.FEMALE_ACTIVITY_NEW_UI_ONBOARDING_BUILDER.id,
				FUNNELS.FEMALE_ACTIVITY_SOMATIC_YOGA_FUNNEL_ONBOARDING_BUILDER.id,
				FUNNELS.FEMALE_ACTIVITY_WALKING_FUNNEL_ONBOARDING_BUILDER.id,
				FUNNELS.FEMALE_FUNNEL_EMOTIONAL_NARRATIVE.id,
				FUNNELS.FEMALE_FUNNEL_EVENT_PERSONALIZATION.id,
				FUNNELS.FEMALE_ACTIVITY_OPTIMIZATION_FOR_CTA.id,
				FUNNELS.FEMALE_ACTIVITY_WALKING_FUNNEL_NEW_START.id,
				FUNNELS.FEMALE_ACTIVITY_CHAIR_YOGA_OPTIMIZATION_FOR_CTA.id,
				FUNNELS.FEMALE_ACTIVITY_CHAIR_YOGA_EVENT_PERSONALIZATION.id,
			].includes(this.funnelType.id)
		},
		IS_FEMALE_OB_FUNNEL() {
			return [
				FUNNELS.FEMALE_FUNNEL_ONBOARDING_BUILDER.id,
				FUNNELS.FEMALE_FUNNEL_EMOTIONAL_NARRATIVE.id,
				FUNNELS.FEMALE_FUNNEL_EVENT_PERSONALIZATION.id,
			].includes(this.funnelType.id)
		},
		IS_MALE_ACTIVITY_OB_FUNNEL() {
			return [FUNNELS.MALE_ACTIVITY_FUNNEL_ONBOARDING_BUILDER.id].includes(this.funnelType.id)
		},
		IS_FEMALE_ACTIVITY_OB_FUNNEL() {
			return [
				FUNNELS.FEMALE_ACTIVITY_FUNNEL_ONBOARDING_BUILDER.id,
				FUNNELS.FEMALE_ACTIVITY_FUNNEL_ONBOARDING_BUILDER_CRO_V1.id,
				FUNNELS.FEMALE_ACTIVITY_NEW_UI_ONBOARDING_BUILDER.id,
				FUNNELS.FEMALE_ACTIVITY_OPTIMIZATION_FOR_CTA.id,
			].includes(this.funnelType.id)
		},
		IS_FEMALE_ACTIVITY_OB_FUNNEL_CRO_V1() {
			return FUNNELS.FEMALE_ACTIVITY_FUNNEL_ONBOARDING_BUILDER_CRO_V1.id === this.funnelType.id
		},
		IS_FEMALE_ACTIVITY_PILATES_OB_FUNNEL() {
			return [FUNNELS.FEMALE_ACTIVITY_PILATES_FUNNEL_ONBOARDING_BUILDER.id].includes(this.funnelType.id)
		},
		IS_FEMALE_ACTIVITY_CHAIR_YOGA_OB_FUNNEL() {
			return [
				FUNNELS.FEMALE_ACTIVITY_CHAIR_YOGA_FUNNEL_ONBOARDING_BUILDER.id,
				FUNNELS.FEMALE_ACTIVITY_CHAIR_YOGA_OPTIMIZATION_FOR_CTA.id,
				FUNNELS.FEMALE_ACTIVITY_CHAIR_YOGA_EVENT_PERSONALIZATION.id,
			].includes(this.funnelType.id)
		},
		IS_FEMALE_ACTIVITY_SOMATIC_YOGA_OB_FUNNEL() {
			return [FUNNELS.FEMALE_ACTIVITY_SOMATIC_YOGA_FUNNEL_ONBOARDING_BUILDER.id].includes(this.funnelType.id)
		},
		IS_FEMALE_ACTIVITY_WALKING_OB_FUNNEL() {
			return [
				FUNNELS.FEMALE_ACTIVITY_WALKING_FUNNEL_ONBOARDING_BUILDER.id,
				FUNNELS.FEMALE_ACTIVITY_WALKING_FUNNEL_NEW_START.id,
			].includes(this.funnelType.id)
		},
		IS_MALE_ACTIVITY_WALKING_OB_FUNNEL() {
			return [FUNNELS.MALE_ACTIVITY_WALKING_FUNNEL_ONBOARDING_BUILDER.id].includes(this.funnelType.id)
		},
		IS_MALE_ACTIVITY_CHAIR_YOGA_OB_FUNNEL() {
			return [FUNNELS.MALE_ACTIVITY_CHAIR_YOGA_FUNNEL_ONBOARDING_BUILDER.id].includes(this.funnelType.id)
		},
		IS_FEMALE_ACTIVITY_ONBOARDING_BUILDER_FUNNEL() {
			return [
				FUNNELS.FEMALE_ACTIVITY_FUNNEL_ONBOARDING_BUILDER.id,
				FUNNELS.FEMALE_ACTIVITY_FUNNEL_ONBOARDING_BUILDER_CRO_V1.id,
				FUNNELS.FEMALE_ACTIVITY_PILATES_FUNNEL_ONBOARDING_BUILDER.id,
				FUNNELS.FEMALE_ACTIVITY_CHAIR_YOGA_FUNNEL_ONBOARDING_BUILDER.id,
				FUNNELS.FEMALE_ACTIVITY_NEW_UI_ONBOARDING_BUILDER.id,
				FUNNELS.FEMALE_ACTIVITY_SOMATIC_YOGA_FUNNEL_ONBOARDING_BUILDER.id,
				FUNNELS.FEMALE_ACTIVITY_WALKING_FUNNEL_ONBOARDING_BUILDER.id,
				FUNNELS.FEMALE_ACTIVITY_OPTIMIZATION_FOR_CTA.id,
				FUNNELS.FEMALE_ACTIVITY_WALKING_FUNNEL_NEW_START.id,
				FUNNELS.FEMALE_ACTIVITY_CHAIR_YOGA_OPTIMIZATION_FOR_CTA.id,
				FUNNELS.FEMALE_ACTIVITY_CHAIR_YOGA_EVENT_PERSONALIZATION.id,
			].includes(this.funnelType.id)
		},
		IS_FEMALE_FUNNEL_EMAIL_BEFORE_PREDICT() {
			return [FUNNELS.FEMALE_FUNNEL_ONBOARDING_BUILDER.id, FUNNELS.FEMALE_FUNNEL_EVENT_PERSONALIZATION.id].includes(
				this.funnelType.id,
			)
		},
		IS_FEMALE_50_PLUS_FUNNEL() {
			const age = this.getPropertyValue('age', true)
			if (age < 50) {
				return false
			}
			return [FUNNELS.FEMALE_FUNNEL_ONBOARDING_BUILDER.id].includes(this.funnelType.id)
		},
	},
}
